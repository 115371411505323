<template>
  <svg v-if="!muted" :height="size" :width="size" viewBox="0 0 24 24">
    <path
      d="M12 16c2.206 0 4-1.794 4-4V6c0-2.217-1.785-4.021-3.979-4.021a.933.933 0 0 0-.209.025A4.006 4.006 0 0 0 8 6v6c0 2.206 1.794 4 4 4z"
      fill="currentColor"/>
    <path
      d="M11 19.931V22h2v-2.069c3.939-.495 7-3.858 7-7.931h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6H4c0 4.072 3.061 7.436 7 7.931z"
      fill="currentColor"/>
  </svg>

  <svg v-else :height="size" :width="size" viewBox="0 0 24 24">
    <path
      d="M21.707 20.293l-3.4-3.4A7.93 7.93 0 0 0 20 12h-2a5.945 5.945 0 0 1-1.119 3.467l-1.449-1.45A3.926 3.926 0 0 0 16 12V6c0-2.217-1.785-4.021-3.979-4.021c-.07 0-.14.009-.209.025A4.006 4.006 0 0 0 8 6v.586L3.707 2.293L2.293 3.707l18 18l1.414-1.414zM6 12H4c0 4.072 3.06 7.436 7 7.931V22h2v-2.069a7.935 7.935 0 0 0 2.241-.63l-1.549-1.548A5.983 5.983 0 0 1 12 18c-3.309 0-6-2.691-6-6z"
      fill="currentColor"/>
    <path d="M8.007 12.067a3.996 3.996 0 0 0 3.926 3.926l-3.926-3.926z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCallMic',

  props: {
    muted: {
      type: Boolean,
      default: false
    },
    size: {
      default: 26,
      required: false
    }
  }
}
</script>
