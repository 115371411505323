<template>
  <div class="call-container">
    <div class="gradient"></div>
    <div class="logo">
      <img src="../assets/logo-robbu-light.svg" alt="Robbu">
    </div>
    <video ref="remoteVideo" autoplay class="remote-video" playsinline></video>
    <video ref="localVideo" autoplay class="local-video" playsinline></video>
    <div v-if="isConnected" class="controls">
      <div
        class="button mic"
        @click.prevent="toggleMicrophone">
        <icon-call-mic :enabled="!isMicrophoneMuted" :size="30" class="icon"/>
      </div>
      <div
        class="button end-call"
        @click.prevent="endCall">
        <icon-call-decline :size="35" class="icon"/>
      </div>
      <div
        class="button sound"
        @click.prevent="toggleRemoteAudio">
        <icon-call-sound :enabled="!isRemoteAudioMuted" :size="30" class="icon"/>
      </div>
    </div>
  </div>
</template>

<script>
import { UserAgent, Inviter } from 'sip.js'
import { onMounted, ref, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import IconCallDecline from '@/components/IconCallDecline.vue'
import IconCallSound from '@/components/IconCallSound.vue'
import IconCallMic from '@/components/IconCallMic.vue'

export default {
  name: 'SipCall',
  components: {
    IconCallMic,
    IconCallSound,
    IconCallDecline
  },
  setup () {
    const route = useRoute()
    const localVideo = ref(null)
    const remoteVideo = ref(null)
    const userAgent = ref(null)
    const session = ref(null)
    const target = ref(route.params.target)
    const isMicrophoneMuted = ref(false)
    const isRemoteAudioMuted = ref(false)
    const isConnected = ref(false) // Variable to track connection status

    const params = {
      ws: 'wss://webrtc.robbuvoice.com:4443/ws',
      name: '5a1349f5-c695-47b1-bf14-6798f886d8c8',
      privateId: '5a1349f5-c695-47b1-bf14-6798f886d8c8',
      publicId: 'sip:5a1349f5-c695-47b1-bf14-6798f886d8c8@webrtc.robbuvoice.com',
      realm: 'webrtc.robbuvoice.com',
      password: '8c8d688f8976-41fb-1b74-596c-5f9431a5',
      ice_servers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        },
        {
          urls: 'stun:stun1.l.google.com:19302'
        },
        {
          urls: 'stun:stun2.l.google.com:19302'
        },
        {
          urls: 'stun:stun3.l.google.com:19302'
        }
      ]
    }

    const initializeSIP = async () => {
      const uri = UserAgent.makeURI(params.publicId)
      if (!uri) {
        console.error('Failed to create URI')
        return
      }

      const userAgentOptions = {
        userAgentString: 'Robbu WebRTC',
        uri: uri,
        transportOptions: {
          server: params.ws,
          connectionTimeout: 10,
          keepAliveInterval: 30,
          keepAliveDebounce: 10
        },
        authorizationUsername: params.privateId,
        authorizationPassword: params.password
      }

      userAgent.value = new UserAgent(userAgentOptions)

      userAgent.value.delegate = {
        onInvite: async (invitation) => {
          session.value = invitation
          await setupSessionEvents(invitation)
          invitation.accept({
            sessionDescriptionHandlerOptions: {
              constraints: {
                audio: true,
                video: true
              }
            }
          })
        }
      }

      await userAgent.value.start()
      console.log('UserAgent started')
      await makeCall()
    }

    const setupSessionEvents = async (session) => {
      const pc = session.sessionDescriptionHandler.peerConnection

      pc.ontrack = async (event) => {
        const remoteStream = new MediaStream()
        remoteStream.addTrack(event.track)

        await nextTick()
        if (remoteVideo.value) {
          remoteVideo.value.srcObject = remoteStream
          isConnected.value = true // Set to true when the connection is established
        }
      }

      const localStream = new MediaStream()
      pc.getSenders().forEach((sender) => {
        if (sender.track) {
          localStream.addTrack(sender.track)
        }
      })

      await nextTick()
      if (localVideo.value) {
        localVideo.value.srcObject = localStream
      }
    }

    const makeCall = async () => {
      const targetURIString = `sip:${target.value}@webrtc.robbuvoice.com`
      const targetURI = UserAgent.makeURI(targetURIString)

      if (!targetURI) {
        console.error('Failed to create target URI', targetURIString)
        return
      }

      const inviter = new Inviter(userAgent.value, targetURI, {
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: true
          }
        }
      })

      await inviter.invite()
      session.value = inviter
      await setupSessionEvents(inviter)
    }

    const toggleMicrophone = () => {
      const pc = session.value.sessionDescriptionHandler.peerConnection
      pc.getSenders().forEach((sender) => {
        if (sender.track.kind === 'audio') {
          sender.track.enabled = !sender.track.enabled
          isMicrophoneMuted.value = !sender.track.enabled
        }
      })
    }

    const endCall = () => {
      if (session.value) {
        session.value.bye()
        session.value = null
        isConnected.value = false
      }
    }

    const toggleRemoteAudio = () => {
      if (remoteVideo.value) {
        remoteVideo.value.muted = !remoteVideo.value.muted
        isRemoteAudioMuted.value = remoteVideo.value.muted
      }
    }

    onMounted(async () => {
      if (target.value) {
        await initializeSIP()
      } else {
        this.message = 'Target value is missing in the URL'
      }
    })

    return {
      endCall,
      localVideo,
      remoteVideo,
      toggleMicrophone,
      toggleRemoteAudio,
      isMicrophoneMuted,
      isRemoteAudioMuted,
      isConnected
    }
  }
}
</script>

<style scoped>
.call-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100px;
  background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 15%, rgba(0,0,0,0) 90%);
  z-index: 90;
}

.remote-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000;
}

.local-video {
  position: absolute;
  bottom: 80px;
  left: 35px;
  width: 250px;
  height: 300px;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 8px;
}

.controls {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  gap: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  padding: 10px;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;

  &:active {
    position: relative;
    top: 1px;
  }
}

.end-call {
  width: 55px;
  height: 55px;
  background-color: red;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  z-index: 100;
  opacity: .8;
}
</style>
