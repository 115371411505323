import { createRouter, createWebHistory } from 'vue-router'
import SipCall from '../components/SipCall.vue'

const routes = [
  {
    path: '/:target',
    name: 'SipCall',
    component: SipCall
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
